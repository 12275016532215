import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import GameServices from "../services/GameServices";
import Cookies from "js-cookie";
import { IonModal, useIonToast } from "@ionic/react";
import "./GameContainer.css";
import axios, { CancelTokenSource } from "axios";

interface ContainerProps {
  signature: string;
  setStatus: Dispatch<SetStateAction<number>>;
}

const GameContainer: React.FC<ContainerProps> = ({ signature, setStatus }) => {
  const audioRef = React.useRef<HTMLAudioElement | null>(null);

  const vowels_array = ["A", "E", "I", "O", "U", "Y"];

  const [paragraph, setParagraph] = useState("");
  const [paragraphId, setParagraphId] = useState("");
  const [vowels, setVowels] = useState([] as string[]);
  const [cancelTokenSourceState, setCancelTokenSourceState] = useState(
    null as null | CancelTokenSource
  );

  const [present] = useIonToast();

  useEffect(() => {
    let timeout: any;
    const initResult = (text: string, id: string) => {
      const resultInit = JSON.parse(Cookies.get(id) ?? "[]");
      if (resultInit.length === 0) {
        for (const c of text) {
          if (isVowel(c) || c === "_") {
            resultInit.push(c);
          }
        }
      }
      setVowels([...resultInit]);
    };

    const checkResult = () => {
      if (cancelTokenSourceState) {
        cancelTokenSourceState.cancel("Request canceled");
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSourceState(newCancelTokenSource);
      clearTimeout(timeout);
      timeout = setTimeout(
        () =>
          GameServices.checkResult(
            vowels,
            paragraphId,
            signature,
            newCancelTokenSource
          ).then((result) => {
            setStatus(result.data);
            if (result.data.status === 2) {
              Cookies.remove(paragraphId);
            } else {
              if (result.data.wrongVowels) {
                present({
                  message: `You have ${result.data.wrongVowels} wrong vowels left!`,
                  duration: 500,
                  position: "top",
                });
              }
            }
          }),
        600
      );
    };

    const fetchParagraph = () => {
      GameServices.getParagraph(signature).then((result) => {
        setParagraph(atob(result?.data.text));
        setParagraphId(result?.data.id);
        initResult(atob(result?.data.text), result?.data.id);
      });
    };

    if (!paragraph) fetchParagraph();

    if (vowels.length > 0 && !vowels.includes("_")) {
      checkResult();
    }

    if (paragraphId && vowels.length > 0) {
      Cookies.set(paragraphId, JSON.stringify(vowels), { expires: 1 });
    }

    return () => {
      if (cancelTokenSourceState) {
        cancelTokenSourceState.cancel("unmounted");
      }
    };
  }, [paragraph, vowels]);

  const flipVowel = (count: number) => {
    if (audioRef.current) {
      audioRef.current.play();
    }
    const resultTemp = [...vowels];
    const index = vowels_array.indexOf(vowels[count]);

    if (index === 5) {
      resultTemp[count] = "A";
    } else {
      resultTemp[count] = vowels_array[index + 1];
    }

    setVowels([...resultTemp]);
  };

  const isLetterOrNumber = (character: string) => {
    return /^[a-zA-Z0-9]$/.test(character);
  };

  const isVowel = (character: string) => {
    return vowels_array.includes(character);
  };

  const modalMissing = useRef<HTMLIonModalElement>(null);
  let count = -1;
  return (
    <div className="container">
      <audio ref={audioRef}>
        <source src={require("../theme/touch.mp3")} type="audio/mpeg" />
      </audio>
      <div className="text">
        <div className="missing">
          <p>Missing letters</p>
          {vowels_array.map((char, index) => {
            return (
              <div
                key={index}
                className="letter box vowel validate"
                style={{ width: "2em", marginRight: "0.2em" }}
              >
                {char}
              </div>
            );
          })}
          <p>Click on the blue cells to select a letter</p>
        </div>
        {paragraph.split(" ").map((word, index1) => {
          return (
            <div key={index1} className="word">
              {word.split("").map((char, index) => {
                if (isLetterOrNumber(char)) {
                  return (
                    <div key={index} className="letter box">
                      {char}
                    </div>
                  );
                } else if (char === "_" || isVowel(char)) {
                  const currentcount = ++count;
                  return (
                    <div
                      key={index}
                      className="letter box vowel"
                      onClick={() => flipVowel(currentcount)}
                    >
                      {vowels[currentcount]}
                    </div>
                  );
                } else if (char === " ") {
                  return <div key={index} className="letter space"></div>;
                } else {
                  return (
                    <div key={index} className="letter">
                      {char}
                    </div>
                  );
                }
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GameContainer;
