import axios from "axios";
import { Cookies } from "react-cookie-consent";

const instance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API,
  headers: { "x-cookie": Cookies.get("cookie") },
});

instance.interceptors.request.use(
  function (config) {
    if (!config.url?.includes("answer")) {
      document.body.classList.add("loading-indicator");
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    document.body.classList.remove("loading-indicator");
    if (response.status === 500 || response.status === 404) {
      alert("An error has occured, please try again later");
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
