import React, { useEffect, useRef, useState } from "react";
import {
  IonButton,
  IonHeader,
  IonModal,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { timeOutline } from "ionicons/icons";
import GameContainer from "../components/GameContainer";
import TimerClock from "../components/TimerClock";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import GameServices from "../services/GameServices";
import Cookies from "js-cookie";
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import "./GamePage.css";

const GamePage: React.FC = () => {
  const [status, setStatus] = useState(0);
  const [milliseconds, setMilliseconds] = useState(0);
  const [signature, setSignature] = useState("");
  const [author, setAuthor] = useState("");
  const [quote, setQuote] = useState("");
  const [feedback, setFeedback] = useState("");

  useEffect(() => {
    const getFP = async () => {
      const key = Cookies.get("cookie");
      if (!key) {
        const fp = await FingerprintJS.load();
        const { visitorId } = await fp.get();
        Cookies.set("cookie", visitorId, { expires: 1 });
        return visitorId;
      }
      return key;
    };

    const checkUserStatus = async () => {
      getFP().then((visitorId) => {
        GameServices.checkUserStatus(visitorId).then((result) => {
          setSignature(visitorId);
          if (result.data.end) {
            setMilliseconds(result.data.end - result.data.start);
          } else if (result.data.start) {
            setMilliseconds(new Date().getTime() - result.data.start);
          } else {
            setMilliseconds(0);
          }
          setAuthor(result.data.author);
          setQuote(result.data.quote);
          setStatus(result.data.status);
        });
      });
    };

    checkUserStatus();
  }, []);

  const startGame = async () => {
    GameServices.startGame(signature).then((result) =>
      setStatus(result.data.status)
    );
  };

  const congratulations = () => {
    const elements = document.getElementsByClassName("vowel");
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      element.classList.add("validate");
    }
  };

  const updateStatus = (data: any) => {
    if (data.status === 2) {
      congratulations();
      setMilliseconds(data.end - data.start);
      setAuthor(data.author);
      setQuote(data.quote);
    }
    setStatus(data.status);
  };

  const getTime = () => {
    let ms = milliseconds;
    let seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    ms = ms % 1000;
    let result = "";

    if (minutes > 0) {
      result += minutes + "min ";
    }

    result += seconds + "s";

    result += " and " + ms + "ms";
    return result;
  };

  const toSentenceCase = (str: string) => {
    if (typeof str !== "string") {
      ("");
    }

    if (!str) {
      return "";
    }

    const newString = str
      .toLowerCase()
      .replace(/(^\s*\w|[.!?]\s*\w)/g, function (c) {
        return c.toUpperCase();
      });
    return newString;
  };

  const updateFeedback = (event: any) => {
    setFeedback(event.target.value);
  };

  const submitFeedback = () => {
    GameServices.subimtFeedback(signature, feedback).then((result) => {
      setFeedback("");
    });
  };

  const getUrl = "https://" + window.location.host;
  const modal = useRef<HTMLIonModalElement>(null);

  return (
    <>
      <IonHeader>
        <IonToolbar className="padding">
          <IonTitle className="title" slot="start">
            novowel
          </IonTitle>
          <span slot="end" style={{ width: "40%" }}>
            <TimerClock
              icon={timeOutline}
              style={{ float: "right" }}
              time={milliseconds}
              status={status}
            />
          </span>
        </IonToolbar>
      </IonHeader>

      {
        {
          0: (
            <IonButton
              className="startGame"
              onClick={async () => await startGame()}
            >
              Start Game
            </IonButton>
          ),
          1: <GameContainer signature={signature} setStatus={updateStatus} />,
          2: (
            <IonModal
              id="results-modal"
              ref={modal}
              isOpen={true}
              backdropDismiss={false}
            >
              <div className="wrapper">
                <h1>Challenge completed!</h1>
                <p>
                  Congratulations, you have been able to successfully complete
                  the quote of the day by {author} in {getTime()}
                </p>

                <p>
                  <i>&quot;{toSentenceCase(quote)}&quot;</i>
                </p>

                <p>Share your accomplishement with your friends:</p>
                <div className="socials">
                  <TwitterShareButton
                    className="social-button"
                    url={getUrl}
                    title={`\u{2705} Completed!\n\nNovowel: ${getTime()}\u{23F0}\n\n\u{1F447}\n`}
                  >
                    <TwitterIcon size={48} round />
                  </TwitterShareButton>
                  <FacebookShareButton
                    className="social-button"
                    url={getUrl}
                    title={`\u{2705} Completed!\n\nNovowel: ${getTime()}\u{23F0}\n\n\u{1F447}\n`}
                  >
                    <FacebookIcon size={48} round />
                  </FacebookShareButton>
                  <WhatsappShareButton
                    className="social-button"
                    url={getUrl}
                    title={`\u{2705} Completed!\n\nNovowel: ${getTime()}\u{23F0}\n\n\u{1F447}\n`}
                  >
                    <WhatsappIcon size={48} round />
                  </WhatsappShareButton>
                  <TelegramShareButton
                    className="social-button"
                    url={getUrl}
                    title={`\u{2705} Completed!\n\nNovowel: ${getTime()}\u{23F0}\n\n\u{1F447}\n`}
                  >
                    <TelegramIcon size={48} round />
                  </TelegramShareButton>
                </div>
                <div className="socials">
                  <IonTextarea
                    aria-label="Feedback"
                    placeholder="Send us your feedback"
                    class="custom"
                    counter={true}
                    maxlength={150}
                    autoGrow={true}
                    value={feedback}
                    onIonInput={(event) => updateFeedback(event)}
                  ></IonTextarea>
                  <IonButton
                    size="small"
                    color="secondary"
                    onClick={submitFeedback}
                  >
                    Submit
                  </IonButton>
                </div>
              </div>
            </IonModal>
          ),
        }[status]
      }
    </>
  );
};

export default GamePage;
