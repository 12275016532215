import { CancelTokenSource } from "axios";
import api from "./axios";
const GameServices = {
  getParagraph: async (signature: string) => {
    return await api.get(`games/${signature}`);
  },

  checkResult: async (
    vowels: string[],
    paragraphId: string,
    signature: string,
    newCancelTokenSource: CancelTokenSource
  ) => {
    try {
      return await api.post(
        `games/answer`,
        {
          result: vowels.join(",").toLowerCase(),
          paragraphId: paragraphId,
          signature: signature,
        },
        {
          cancelToken: newCancelTokenSource.token,
        }
      );
    } catch (err: any) {
      return {
        data: {
          status: 1,
        },
      };
    }
  },

  checkUserStatus: async (signature: string) => {
    return await api.get(`games/${signature}/status`);
  },

  startGame: async (signature: string) => {
    return await api.post(`games/${signature}/start`);
  },

  subimtFeedback: async (signature: string, feedback: string) => {
    return await api.post(`games/feedback`, {
      signature: signature,
      feedback: feedback,
    });
  },
};

export default GameServices;
