import React from "react";
import { IonContent, IonHeader, IonTitle, IonToolbar } from "@ionic/react";
import { Adsense } from "@ctrl/react-adsense";
import "./HelpPage.css";

const HelpPage: React.FC = () => {
  return (
    <>
      <IonHeader>
        <IonToolbar className="padding">
          <IonTitle className="title" style={{ textAlign: "center" }}>
            novowel
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="help-text">
          {`The objective of the game is to fill in the missing letters in a given
          text. You will be presented with a sentence or a phrase that has some of its letters removed. 
          Your task is to correctly guess the missing letter and complete the sentence. To start filling the text, simply
          click on the empty cell and select the letter you think is correct
          from the provided options. You can only choose one letter per blank
          space, so make sure to think carefully before making your choice. Once
          you have filled in all of the letter, if you have guessed all of the
          letters correctly, your time will be saved. If not, you can try again
          until you get it right. Remember, the game is designed to be
          challenging and fun, so don't get discouraged if you don't get it
          right on your first try. Keep practicing and have fun!`}
        </div>
      </IonContent>
    </>
  );
};

export default HelpPage;
