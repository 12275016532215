import React from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { gameController, help } from "ionicons/icons";

import GamePage from "./pages/GamePage";
import HelpPage from "./pages/HelpPage";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./fonts/LeckerliOne-Regular.ttf";

setupIonicReact();

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonTabs className="iontabs">
        <IonRouterOutlet>
          <Route exact path="/game">
            <GamePage />
          </Route>
          <Route exact path="/help">
            <HelpPage />
          </Route>
          <Route exact path="/">
            <Redirect to="/help" />
          </Route>
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="game" href="/game">
            <IonIcon aria-hidden="true" icon={gameController} />
            <IonLabel>Game</IonLabel>
          </IonTabButton>
          <IonTabButton tab="help" href="/help">
            <IonIcon aria-hidden="true" icon={help} />
            <IonLabel>Help</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  </IonApp>
);

export default App;
