import React, { CSSProperties } from "react";
import Timer from "react-timer-wrapper";
import Timecode from "react-timecode";
import { IonIcon, IonLabel } from "@ionic/react";

interface TimerProps {
  text?: string;
  icon?: string;
  style?: CSSProperties;
  time: number;
  status: number;
}

const TimerClock: React.FC<TimerProps> = ({
  text,
  icon,
  style,
  time,
  status,
}) => {
  if (status === 1) {
    return (
      <Timer
        active
        duration={null}
        time={time}
        style={{ display: "flex", alignItems: "center", ...style }}
      >
        {text ? (
          <IonLabel>{text}</IonLabel>
        ) : (
          <IonIcon
            icon={icon}
            style={{ marginRight: "2%", fontSize: "20px" }}
            color="primary"
          ></IonIcon>
        )}
        <Timecode />
      </Timer>
    );
  } else {
    return <></>;
  }
};

export default TimerClock;
